import React, { useState, useContext } from "react"
import Hero from "../components/Hero"
import Experts from "../components/Experts"
import Seo from "../components/seo"
import { useAuthContext } from "../hooks/useAuthContext"
import { graphql } from "gatsby"
import { StateContext } from "../helpers/StateProvider"
import exportIcon from "../assets/icons/graphic-designer.svg";
import expertImage from "../assets/images/graphic-designer.webp";
const GraphicDesigner = props => {
  const { authIsReady } = useAuthContext()
  let inverted = true
  const { language, setLanguage } = useContext(StateContext)

  return (
    <>
      {props.data.allPropertyType.edges && props.data.allPropertyType.edges && (
        <>
          <Seo title="Graphic Designer" />
          <Hero
            arabic={language}
            title={
              language == false
                ? "Graphic Designer"
                : "مصمم جرافيك"
            }
            subTitle={
              language == false
                ? "Crafting Visual Stories: Where Creativity Meets Design Excellence"
                : "صياغة القصص المرئية: حيث يلتقي الإبداع مع التميز في التصميم"
            }
          />
          {/* <Widgets widgetsData='property-type'/>  */}

      
              <Experts
                inverted={false}
                Icon={exportIcon}
                image={expertImage}
                title={
                  language == false
                    ? "Graphic Designer"
                    : "طوبوغرافي محلف"
                }
                description={
                  language == false
                    ? "we understand the power of compelling visuals and their ability to communicate your brand's story, values, and objectives. Our graphic design service is tailored to elevate your brand's presence, engaging your audience with innovative, memorable, and impactful designs. From the initial concept to the final output, our creative experts work tirelessly to ensure your vision is brought to life in the most vibrant way possible."
                    : "نحن نتفهم قوة العناصر المرئية الجذابة وقدرتها على إيصال قصة علامتك التجارية وقيمها وأهدافها. تم تصميم خدمة التصميم الجرافيكي لدينا لتعزيز حضور علامتك التجارية، وإشراك جمهورك بتصميمات مبتكرة لا تُنسى ومؤثرة. بدءًا من المفهوم الأولي وحتى المخرج النهائي، يعمل خبراؤنا المبدعون بلا كلل لضمان تجسيد رؤيتك على أرض الواقع بأكثر الطرق حيوية قدر الإمكان."
                }
                label={
                  language == false
                  ? "Call our Designer"
                  : "اتصل بمصممنا"
                }
                link={"tel:+9613948739"}
                arabic={language}
              />
          <br/><br/><br/>
        </>
      )}
    </>
  )
}

export const query = graphql`
  {
    allUsers {
      edges {
        node {
          first_name
          id
          arabic_language
        }
      }
    }
    allPage(filter: { name: { eq: "property-type" } }) {
      edges {
        node {
          name
          title
          title_ar
          id
          description
          description_ar
          widgets {
            name
            type
          }
        }
      }
    }
    allPropertyType {
      edges {
        node {
          description
          description_ar
          id
          image
          link
          title
          title_ar
          value
          subcategories {
            label
            label_ar
            value
          }
          localImage {
            childImageSharp {
              fixed(width: 1000) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
    top: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "top" } }
    ) {
      edges {
        node {
          classname
          id
          label_ar

          label
          link
          type
        }
      }
    }
    followus: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "followus" } }
    ) {
      edges {
        node {
          classname
          id

          label
          link
          type
        }
      }
    }
    browse: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "browse" } }
    ) {
      edges {
        node {
          classname
          id
          label_ar

          label
          link
          type
        }
      }
    }
    explore: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "explore" } }
    ) {
      edges {
        node {
          classname
          id
          label_ar

          label
          link
          type
        }
      }
    }
    ourservices: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "ourservices" } }
    ) {
      edges {
        node {
          classname
          id
          label_ar

          label
          link
          type
        }
      }
    }
    topcategories: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "topcategories" } }
    ) {
      edges {
        node {
          classname
          id
          label_ar

          label
          link
          type
        }
      }
    }
    widget7: widget(name: { eq: "looking-for-exposure" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
      localImage {
        childImageSharp {
          fixed(width: 1140, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    widget8: widget(name: { eq: "who-we-are" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
    }
  }
`
export default GraphicDesigner
